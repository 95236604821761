import {
  ConfigurationParserDefinition,
  InferFallbackConfiguration,
} from './types';

export const createFallbackConfig = <D extends ConfigurationParserDefinition>(
  definition: D
): InferFallbackConfiguration<D> => {
  const result: Partial<InferFallbackConfiguration<D>> = {};

  for (const key in definition) {
    result[key] = definition[key].defaultValue;
  }

  return result as InferFallbackConfiguration<D>;
};
