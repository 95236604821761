import {
  ConfigurationPropertyParser,
  ConfigurationPropertyValue,
  PreconfiguredConfigurationPropertyParser,
} from './types';

const createParser = <Result extends ConfigurationPropertyValue>(
  defaultValue: Result,
  parsingFunction: (value: string) => Result
): PreconfiguredConfigurationPropertyParser<Result> => {
  Object.defineProperty(parsingFunction, 'defaultValue', {
    value: defaultValue,
    writable: false,
  });
  return parsingFunction as PreconfiguredConfigurationPropertyParser<Result>;
};

export const str: ConfigurationPropertyParser<string> = (defaultValue) =>
  createParser(defaultValue, (value) => value || defaultValue);

export const bool: ConfigurationPropertyParser<boolean> = (defaultValue) =>
  createParser(defaultValue, (value) => {
    if (['true', '1'].includes(value.toLowerCase())) {
      return true;
    }

    if (['false', '0'].includes(value.toLowerCase())) {
      return false;
    }

    return defaultValue;
  });

export const int: ConfigurationPropertyParser<number> = (defaultValue) =>
  createParser(defaultValue, (value) => {
    const possibleInteger = parseInt(value, 10);

    if (isNaN(possibleInteger)) {
      return defaultValue;
    }

    if (!Number.isSafeInteger(possibleInteger)) {
      return defaultValue;
    }

    return possibleInteger;
  });

export const oneof = <T extends ConfigurationPropertyValue>(
  defaultValue: T,
  possibleValues: T[]
): PreconfiguredConfigurationPropertyParser<T> =>
  createParser<T>(defaultValue, (value) => {
    if (possibleValues.includes(value as T)) {
      return value as T;
    }

    return defaultValue;
  });
