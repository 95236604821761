import { trackEvent } from '@checkout-ui/demo-shop/services';
import { useViewportToggler } from '@checkout-ui/demo-shop-viewport-toggler';
import { Banner } from '@checkout-ui/design-system/Banner';
import { BodyText } from '@checkout-ui/design-system/BodyText';
import { InputLabel } from '@checkout-ui/design-system/InputLabel';
import { Radio, RadioProps } from '@checkout-ui/design-system/Radio';
import { Text } from '@checkout-ui/design-system/Text';
import {
  COUNTRIES_SCENARIO_LISTS,
  Intent,
  PLACE_ORDER_MESSAGE,
  TOPICS,
  useCrossDocumentMessenger,
} from '@checkout-ui/shared/cross-document-messenger';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import startCase from 'lodash/startCase';
import { FC, useCallback, useEffect, useMemo } from 'react';

import styles from './configuration-flows.module.scss';
import { useConfigurationPanel } from './context/ConfigurationPanelContext';

export const ConfigurationFlows: FC = () => {
  const {
    currentScenario,
    setCurrentScenario,
    setIsCurrentFlowFinal,
    isCurrentFlowFinal,
    setScenarioRestartCount,
    config,
  } = useConfigurationPanel();
  const { crossDocumentMessenger, isReady } = useCrossDocumentMessenger();
  const { toggleNav, isNavShown } = useViewportToggler();

  const preDefinedScenarios = useMemo(
    () =>
      COUNTRIES_SCENARIO_LISTS[config.country] ||
      COUNTRIES_SCENARIO_LISTS.__DEFAULT__,
    [config.country]
  );

  const handlePlaceOrderMessage = useCallback(
    ({ message }: { message?: PLACE_ORDER_MESSAGE }) => {
      if (!message) return;

      switch (message) {
        case 'place-order:success':
        case 'place-order:failure':
          return setIsCurrentFlowFinal(true);
      }
    },
    [setIsCurrentFlowFinal]
  );

  const handleCustomIntent = useCallback(
    ({ message: intent }: { message?: Intent }) => {
      if (intent === 'custom') {
        setCurrentScenario('custom');
      }
    },
    [setCurrentScenario]
  );

  const handleChange: RadioProps<Intent>['onChange'] = (event) => {
    const scenario = event.target.value as Intent;

    trackEvent(`${startCase(scenario)} Scenario selected`);

    setCurrentScenario(scenario);

    if (isNavShown) {
      toggleNav();
    }

    if (isReady) {
      crossDocumentMessenger.publish(TOPICS.intent, {
        message: scenario,
      });
    }

    if (isCurrentFlowFinal) {
      setScenarioRestartCount((count) => count + 1);
      setIsCurrentFlowFinal(false);
    }
  };

  useEffect(() => {
    if (isReady) {
      crossDocumentMessenger.publish(TOPICS.intent, {
        message: currentScenario,
      });
    }
  }, [config.country, crossDocumentMessenger, currentScenario, isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    crossDocumentMessenger.subscribe(TOPICS.intent, handleCustomIntent);

    return () => {
      crossDocumentMessenger.unsubscribe(TOPICS.intent, handleCustomIntent);
    };
  }, [handleCustomIntent, crossDocumentMessenger, isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    crossDocumentMessenger.subscribe(
      TOPICS.place_order,
      handlePlaceOrderMessage
    );

    return () =>
      crossDocumentMessenger.unsubscribe(
        TOPICS.place_order,
        handlePlaceOrderMessage
      );
  }, [handlePlaceOrderMessage, crossDocumentMessenger, isReady]);

  return (
    <div
      id="send-message-test"
      data-test="configuration-flows"
      className={styles['configuration-flows']}
    >
      <BodyText
        fontWeight="semi-bold"
        className={styles['configuration-flows__description']}
      >
        <FormattedMessage id="demo-shop.configuration-flows.description" />
      </BodyText>

      <div className={styles['configuration-flows__risk-check-names']}>
        {preDefinedScenarios.map(({ translationKey, intent }) => {
          return (
            <div
              key={translationKey}
              className={styles['configuration-flows__risk-check-name']}
              data-test={`risk-check-${intent}__container`}
            >
              <Radio
                checked={currentScenario === intent}
                onChange={handleChange}
                id={intent}
                value={intent}
                dataTest={`risk-check-${intent}__radio`}
              />
              <InputLabel
                htmlFor={intent}
                dataTest={`risk-check-${intent}__label`}
              >
                <Text
                  className={
                    styles['configuration-flows__risk-check-name__text']
                  }
                >
                  <FormattedMessage id={translationKey} />
                </Text>
              </InputLabel>
            </div>
          );
        })}
      </div>
      <Banner className={styles['configuration-flows__info-banner']}>
        <BodyText variant="body-text-2">
          <FormattedMessage id="demo-shop.configuration-flows.info.banner" />
        </BodyText>
      </Banner>
    </div>
  );
};

export default ConfigurationFlows;
