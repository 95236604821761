//language is used for translations (copy)
//TODO: values should come from a mapper country to language check-175
export type LanguageType = // both locale and language can have de-DE or de formats , in the future we can support more languages than locales

    | 'en'
    | 'en-US'
    | 'en-GB'
    | 'de'
    | 'de-DE'
    | 'de-AT'
    | 'de-CH'
    | 'nl'
    | 'nl-NL'
    | 'fi'
    | 'fi-FI'
    | 'fr'
    | 'fr-FR'
    | 'sv'
    | 'sv-SE'
    | 'no'
    | 'nb-NO'
    | 'da-DK'
    | 'da'
    | 'es'
    | 'es-ES'
    | 'it'
    | 'it-IT';

//locale used for formats
export type LocaleType = LanguageType; // product decision to simplify the locale<>language relationship

export enum SupportedLocaleTag {
  AmericanEnglish = 'en-US',
  BritishEnglish = 'en-GB',
  German = 'de-DE',
  AustrianGerman = 'de-AT',
  SwissGerman = 'de-CH',
  Dutch = 'nl-NL',
  French = 'fr-FR',
  Swedish = 'sv-SE',
  Norwegian = 'nb-NO',
  Finnish = 'fi-FI',
  Danish = 'da-DK',
  Spanish = 'es-ES',
  Italian = 'it-IT',
}

export enum SupportedLanguage {
  English = 'en',
  German = 'de',
  Dutch = 'nl',
  French = 'fr',
  Swedish = 'sv',
  Norwegian = 'no',
  NorwegianBokmal = 'nb',
  Finnish = 'fi',
  Danish = 'da',
  Spanish = 'es',
  Italian = 'it',
}
