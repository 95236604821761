import type { Client, ClientOptions, Scope } from '@sentry/core';
import type { ErrorBoundary, withErrorBoundary } from '@sentry/react';

import type { Logger } from '../logger';

export enum SentryServiceEnvironment {
  Local = 'local',
  Development = 'development',
  Sandbox = 'sandbox',
  Production = 'production',
}

export type SentryServiceInitOptions = {
  dsn: string;
  environment: SentryServiceEnvironment;
  debug?: boolean;
  enabled: boolean;
  logger: Logger;
};

export type SentryServiceImplementationPackage = {
  init: (
    options: Omit<ClientOptions, 'transport' | 'integrations' | 'stackParser'>
  ) => Client | undefined;
  ErrorBoundary?: typeof ErrorBoundary;
  withErrorBoundary?: typeof withErrorBoundary;
};

export type SentryService = {
  init: (options: SentryServiceInitOptions) => Promise<void>;
  captureException: Scope['captureException'];
  ErrorBoundary?: typeof ErrorBoundary;
  withErrorBoundary?: typeof withErrorBoundary;
};
