import { trackEvent } from '@checkout-ui/demo-shop/services';
import { Caption } from '@checkout-ui/design-system/Caption';
import { Icon } from '@checkout-ui/design-system/Icon';
import { List } from '@checkout-ui/design-system/List';
import { Close } from '@checkout-ui/internal-svgs/Close';
import { Desktop } from '@checkout-ui/internal-svgs/Desktop';
import { Menu } from '@checkout-ui/internal-svgs/Menu';
import { Mobile } from '@checkout-ui/internal-svgs/Mobile';
import { Tablet } from '@checkout-ui/internal-svgs/Tablet';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import clsx from 'clsx';

import { ViewportType } from './context/types';
import { useViewportToggler } from './context/ViewPortContext';
import styles from './viewport-toggler.module.scss';

const viewportList = [
  {
    type: ViewportType.DESKTOP,
    icon: Desktop,
  },
  {
    type: ViewportType.TABLET,
    icon: Tablet,
  },
  {
    type: ViewportType.MOBILE,
    icon: Mobile,
  },
];

export function ViewportToggler() {
  const { handleViewportChange, viewport, toggleNav, isNavShown } =
    useViewportToggler();

  const NavIcon = isNavShown ? Close : Menu;

  const ViewPortList = () => (
    <List className={styles['viewport']} dataTest="viewport-toggler">
      {viewportList.map((viewportListItem) => {
        return (
          <List.Item
            key={viewportListItem.type}
            alignItems="center"
            justifyContent="center"
            className={clsx(
              styles['viewport__item'],
              styles[`'viewport__item--${viewportListItem.type}`],
              viewport === viewportListItem.type &&
                styles['viewport__item--active']
            )}
            dataTest={`viewport-${viewportListItem.type}`}
            onClick={() => {
              handleViewportChange(viewportListItem.type);
              trackEvent(`${viewportListItem.type} view selected`);
            }}
          >
            <Icon icon={viewportListItem.icon} />
            <Caption color="text--inherit">
              <FormattedMessage
                id={`demo-shop.viewport-toggler.${viewportListItem.type}`}
              />
            </Caption>
          </List.Item>
        );
      })}
    </List>
  );

  return (
    <div className={styles['container']}>
      <Icon icon={NavIcon} className={styles['menu']} onClick={toggleNav} />
      {isNavShown ? (
        <div className={styles['viewport__placeholder']} />
      ) : (
        <ViewPortList />
      )}
    </div>
  );
}

export default ViewportToggler;
