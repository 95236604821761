export enum CountryType {
  DE = 'DE',
  SE = 'SE',
  AT = 'AT',
  CH = 'CH',
  GB = 'GB',
  NL = 'NL',
  FR = 'FR',
  NO = 'NO',
  FI = 'FI',
  DK = 'DK',
  ES = 'ES',
  IT = 'IT',
}
