/* eslint-disable no-restricted-globals */
// the source code was copied from: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2a-add-the-segment-snippet
// and modified to match Billie use-case
function initSegmentScript(segmentPublicKey, appAnalyticsKey, segmentOptions) {
  // define the key where the global analytics object will be accessible
  const analyticsKey = appAnalyticsKey || 'analytics';
  // Create a queue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (window[analyticsKey] = window[analyticsKey] || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already just ignore the following calls.
  if (analytics.invoked) return;

  // Invoked flag, to make sure the snippet is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'screen',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
    'register',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (e) {
    return function (...args) {
      if (window[analyticsKey].initialized) {
        // Sometimes users assigned analytics to a variable before analytics is done loading, resulting in a stale reference.
        // If so, proxy any calls to the 'real' analytics instance.
        window[analyticsKey][e].bind(window[analyticsKey]);
        return window[analyticsKey][e](...args);
      }

      // Add buffered page context object so page information is always up-to-date
      if (
        ['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) >
        -1
      ) {
        const c = document.querySelector("link[rel='canonical']");
        args.push({
          __t: 'bpc',
          c: (c && c.getAttribute('href')) || undefined,
          p: location.pathname,
          u: location.href,
          s: location.search,
          t: document.title,
          r: document.referrer,
        });
      }

      args.unshift(e);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from Segment CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const t = document.createElement('script');
    t.type = 'text/javascript';
    t.async = true;
    t.setAttribute('data-global-segment-analytics-key', analyticsKey);
    t.src =
      'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode?.insertBefore(t, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = segmentPublicKey;

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '5.2.1';
  analytics.load(segmentPublicKey, segmentOptions);
}

export { initSegmentScript };
