import { Scope } from '@sentry/core';

import type {
  SentryService,
  SentryServiceImplementationPackage,
  SentryServiceInitOptions,
} from './types';

export const createSentryService = (
  sentryImplementation: SentryServiceImplementationPackage
): SentryService => {
  const scope = new Scope();

  const init = async ({
    dsn,
    environment,
    debug = false,
    enabled,
    logger,
  }: SentryServiceInitOptions) => {
    if (typeof window === 'undefined') {
      logger.debug('sentry init called on server, skipping...');
      return;
    }

    const options: Parameters<typeof sentryImplementation.init>[0] = {
      dsn,
      environment,
      enabled,
      debug,
      sampleRate: 0.2,
      tracesSampleRate: environment === 'production' ? 0.2 : 0,
      beforeSend: (event) => {
        if (typeof window === 'undefined') {
          return null; // do not send events on server
        }
        return event;
      },
    };

    logger.debug(
      `initializing sentry with options: ${JSON.stringify(options, null, 2)}`
    );

    const clientInstance = sentryImplementation.init(options);

    if (!clientInstance) {
      logger.warn('no sentry client instance created, skipping client setup');
      return;
    }

    scope.setClient(clientInstance);

    logger.info('sentry client created successfully');
  };

  return {
    init,
    captureException: (...args) => scope.captureException(...args),
    ...('ErrorBoundary' in sentryImplementation
      ? { ErrorBoundary: sentryImplementation.ErrorBoundary }
      : {}),
    ...('withErrorBoundary' in sentryImplementation
      ? { withErrorBoundary: sentryImplementation.withErrorBoundary }
      : {}),
  };
};
