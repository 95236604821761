import { createSentryService } from '@checkout-ui/shared-services';
import type {
  ErrorBoundary as ReactErrorBoundary,
  withErrorBoundary as withReactErrorBoundary,
} from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';

const service = createSentryService(Sentry);

export const { init, captureException } = service;

export const ErrorBoundary = service.ErrorBoundary as typeof ReactErrorBoundary;
export const withErrorBoundary =
  service.withErrorBoundary as typeof withReactErrorBoundary;
