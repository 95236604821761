import { trackEvent } from '@checkout-ui/demo-shop/services';
import { BodyText } from '@checkout-ui/design-system/BodyText';
import { ButtonPrimary } from '@checkout-ui/design-system/Button/ButtonPrimary';
import { InputLabel } from '@checkout-ui/design-system/InputLabel';
import { Radio } from '@checkout-ui/design-system/Radio';
import { Text } from '@checkout-ui/design-system/Text';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import { FC } from 'react';

import styles from './configure-integration.module.scss';
import { useConfigurationPanel } from './context/ConfigurationPanelContext';
import { IntegrationType } from './context/types';
import { CountrySelector } from './CountrySelector';
import { LanguageSelector } from './LanguageSelector';

export const ConfigurationIntegration: FC = () => {
  const { config, updateConfigurations, handleConfigIntegrationChange } =
    useConfigurationPanel();

  return (
    <div
      className={styles['integration']}
      data-test="configuration-integration"
    >
      <BodyText
        className={styles['integration__heading']}
        fontWeight="semi-bold"
      >
        <FormattedMessage id="demo-shop.configuration.language-country" />
      </BodyText>
      <div className={styles['integration__country']}>
        <CountrySelector />
      </div>
      <div className={styles['integration__language']}>
        <LanguageSelector />
      </div>
      <div className={styles['integration__type']}>
        <BodyText
          fontWeight="semi-bold"
          className={styles['integration__type__heading']}
        >
          <FormattedMessage id="demo-shop.configuration.integration_type" />
        </BodyText>

        <div className={styles['content']}>
          <div className={styles['content__item']}>
            <Radio
              name="integration_type"
              id={IntegrationType.KP}
              value={IntegrationType.KP}
              dataTest={IntegrationType.KP}
              checked={config.integration_type === IntegrationType.KP}
              onChange={handleConfigIntegrationChange}
            />
            <InputLabel htmlFor={IntegrationType.KP}>
              <BodyText variant="body-text-2" fontWeight="semi-bold">
                <FormattedMessage id="demo-shop.configuration.integration_type_KP" />
              </BodyText>
            </InputLabel>
          </div>
        </div>
      </div>
      <div className={styles['integration__cta']}>
        <ButtonPrimary
          type="button"
          dataTest="cta"
          onClick={() => {
            updateConfigurations();
            trackEvent('Configuration confirmed');
          }}
        >
          <Text>
            <FormattedMessage id="demo-shop.configuration.cta" />
          </Text>
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default ConfigurationIntegration;
