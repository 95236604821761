import { trackEvent } from '@checkout-ui/demo-shop/services';
import { BodyText } from '@checkout-ui/design-system/BodyText';
import { Selectv2 } from '@checkout-ui/design-system/Selectv2/Selectv2';
import { Text } from '@checkout-ui/design-system/Text';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import { useSelect } from 'downshift';
import { useMemo } from 'react';

import { useConfigurationPanel } from './context/ConfigurationPanelContext';
import { ConfigType, SelectOption } from './context/types';
import styles from './CountrySelector.module.scss';

export const CountrySelector = () => {
  const { config, handleConfigChange } = useConfigurationPanel();

  const regionNames = useMemo(
    () => new Intl.DisplayNames([config.language], { type: 'region' }),
    [config.language]
  );

  const countryOptions: SelectOption[] = config.available_countries.map(
    (countryCode) => {
      const cc = countryCode.toUpperCase();

      return {
        value: cc,
        text: regionNames.of(cc) || cc,
      };
    }
  );

  const getConfigCountry = (config: ConfigType): SelectOption | null => {
    return (
      countryOptions.find((country) => country.value === config.country) || null
    );
  };

  const {
    selectedItem,
    highlightedIndex,
    getItemProps,
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getLabelProps,
  } = useSelect({
    items: countryOptions,
    initialSelectedItem: getConfigCountry(config),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      handleConfigChange(newSelectedItem || null, 'country');
      trackEvent(`${newSelectedItem?.value.toLowerCase()} country selected`);
    },
  });

  const countrySelectOptions = countryOptions.map((item, index) => {
    return (
      <Selectv2.Option
        key={item.value}
        value={item.value}
        isSelected={selectedItem ? selectedItem.value === item.value : false}
        isHighlighted={highlightedIndex === index}
        {...getItemProps({
          item,
          index,
        })}
        dataTest={`country__option--${item.value.toLowerCase()}`}
      >
        <BodyText>{item.text}</BodyText>
      </Selectv2.Option>
    );
  });

  return (
    <Selectv2
      isOpen={isOpen}
      data-test="country"
      overlayClassName={styles['country_select']}
      value={selectedItem ? <Text>{selectedItem.text}</Text> : undefined}
      menuProps={getMenuProps()}
      triggerProps={getToggleButtonProps()}
      labelProps={getLabelProps()}
      placeholder={
        <Text>
          <FormattedMessage id="demo-shop.configuration.country-placeholder" />
        </Text>
      }
      // FIXME: a possible issue with the UI library, check the reason why we need to override styles
      //  see PURCHASE-841 for details
      label={
        <BodyText tag="span">
          <FormattedMessage id="demo-shop.configuration.country" />
        </BodyText>
      }
    >
      {countrySelectOptions}
    </Selectv2>
  );
};
