// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../../node_modules/@types/segment-analytics/index.d.ts" />

import { logger as defaultLogger } from '@checkout-ui/shared-logger';

import type { Logger } from '../../logger';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { initSegmentScript } from './init';
import type {
  MetaData,
  SegmentOptions,
  SegmentService,
  TrackEventType,
} from './types';

export const createSegmentService = (
  analyticsKey = 'analytics'
): SegmentService => {
  let analytics: SegmentAnalytics.AnalyticsJS;

  const state: MetaData = {
    session_id: undefined,
    integration_type: undefined,
    locale: undefined,
    country: undefined,
    channel: undefined,
    merchant_legal_name: undefined,
  };

  let logger: Logger = defaultLogger;

  const setState = ({
    session_id,
    integration_type,
    locale,
    country,
    channel,
    merchant_legal_name,
  }: MetaData) => {
    if (session_id) {
      state.session_id = session_id;
    }

    if (integration_type) {
      state.integration_type = integration_type;
    }

    if (locale) {
      state.locale = locale;
    }

    if (country) {
      state.country = country;
    }

    if (channel) {
      state.channel = channel;
    }

    if (merchant_legal_name) {
      state.merchant_legal_name = merchant_legal_name;
    }
  };

  const init = (
    segmentPublicKey: string,
    appLogger: Logger,
    options?: SegmentOptions
  ) => {
    logger = appLogger;

    if (typeof window === 'undefined') {
      logger.info(
        'segment analytics init requested in server environment, skipping...'
      );
      return;
    }

    if (segmentPublicKey) {
      logger.info(
        `segment analytics init requested for window.${analyticsKey} with "${segmentPublicKey}" key`
      );
      initSegmentScript(segmentPublicKey, analyticsKey, options);

      // dangerous type conversion, we should look for a better solution
      analytics = (
        window as Window & typeof globalThis & Record<string, unknown>
      )[analyticsKey] as SegmentAnalytics.AnalyticsJS;
    }
  };

  const setMetaData = ({
    locale,
    country,
    integration_type,
    session_id,
    channel,
    merchant_legal_name,
  }: MetaData) => {
    if (typeof window === 'undefined') {
      return;
    }

    const metadata = {
      session_id,
      integration_type,
      locale,
      country,
      channel,
      merchant_legal_name,
    };

    setState(metadata);

    try {
      analytics.identify(metadata);
    } catch (err) {
      if (!analytics) {
        logger.debug(
        'segment analytics not initialized, set metadata request:',
        metadata

        );
      } else {
        logger.warn('could not call analytics.identify', err);
      }
    }
  };

  const trackEvent: TrackEventType = (...args) => {
    if (typeof window === 'undefined') {
      return;
    }

    const [eventName, properties, ...rest] = args;

    const metaData = {
      session_id: state.session_id,
      locale: state.locale,
      country: state.country,
      integration_type: state.integration_type,
      channel: state.channel,
      merchant_legal_name: state.merchant_legal_name,
    };

    try {
      analytics.track(
        eventName,
        {
          ...properties,
          ...metaData,
        },
        ...rest
      );
    } catch (err) {
      if (!analytics) {
        logger.debug(
          'segment analytics not initialized, track event request:',
          eventName,
          { ...properties, ...metaData },
          ...rest
        );
      } else {
        logger.warn('could not call analytics.track', err);
      }
    }
  };

  return {
    init,
    setMetaData,
    trackEvent,
  };
};
