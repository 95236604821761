import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ConfigurationContextValue,
  ConfigurationLoader,
  ConfigurationLoaderEventHandler,
  ConfigurationParserDefinition,
  ConfigurationStatus,
  InferConfiguration,
} from './types';

export const createConfigurationContextProvider = <
  D extends ConfigurationParserDefinition
>({
  ConfigurationContext,
  configurationLoader,
}: {
  ConfigurationContext: React.Context<ConfigurationContextValue<D>>;
  configurationLoader: ConfigurationLoader<D>;
}) => {
  const ConfigurationContextProvider: React.FC<PropsWithChildren> = ({
    children,
  }) => {
    const [status, setStatus] = useState(ConfigurationStatus.Initial);
    const [config, setConfig] = useState<InferConfiguration<D>>(
      configurationLoader.fallbackConfig
    );
    const [error, setError] = useState<null | Error>(null);

    useEffect(() => {
      const onLoading: ConfigurationLoaderEventHandler<D> = () => {
        setError(null);
        setStatus(ConfigurationStatus.Loading);
      };

      configurationLoader.on(configurationLoader.events.Loading, onLoading);

      const onError: ConfigurationLoaderEventHandler<D> = ({
        error: nextError,
        config: nextConfig,
      }) => {
        setError(nextError);
        setConfig(nextConfig);
        setStatus(ConfigurationStatus.Error);
      };

      configurationLoader.on(configurationLoader.events.Error, onError);

      const onReady: ConfigurationLoaderEventHandler<D> = ({
        config: nextConfig,
      }) => {
        setError(null);
        setConfig(nextConfig);
        setStatus(ConfigurationStatus.Ready);
      };

      configurationLoader.on(configurationLoader.events.Ready, onReady);

      return () => {
        configurationLoader.off(configurationLoader.events.Loading, onLoading);
        configurationLoader.off(configurationLoader.events.Error, onError);
        configurationLoader.off(configurationLoader.events.Ready, onReady);
      };
    }, []);

    const value = useMemo(
      () => ({
        src: configurationLoader.src,
        status,
        config,
        error,
      }),
      [status, config, error]
    );

    return (
      <ConfigurationContext.Provider value={value}>
        {status === ConfigurationStatus.Initial ||
        status === ConfigurationStatus.Loading
          ? null
          : children}
      </ConfigurationContext.Provider>
    );
  };

  const useConfigurationContext = () => {
    const context = useContext(ConfigurationContext);

    if (context === undefined) {
      throw new Error(
        `useConfigurationContext must be used within a ConfigurationContextProvider`
      );
    }

    return context as ConfigurationContextValue<D>;
  };

  return {
    ConfigurationContextProvider,
    useConfigurationContext,
  };
};
