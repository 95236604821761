import { trackEvent } from '@checkout-ui/demo-shop/services';
import { BodyText } from '@checkout-ui/design-system/BodyText';
import { Heading } from '@checkout-ui/design-system/Heading';
import { Icon } from '@checkout-ui/design-system/Icon';
import Logo from '@checkout-ui/design-system/Logo';
import { PreviousArrow } from '@checkout-ui/internal-svgs/PreviousArrow';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import { FC } from 'react';

import ConfigurationFlows from './configuration-flows';
import styles from './configuration-panel.module.scss';
import ConfigurationIntegration from './configure-integration';
import { useConfigurationPanel } from './context/ConfigurationPanelContext';
import { State } from './context/types';

export const ConfigurationPanel: FC = () => {
  const { configState, isViewportEnabled, setIsViewportEnabled } =
    useConfigurationPanel();

  return (
    <div className={styles['container']} data-test="configuration-panel">
      <div className={styles['content']}>
        <div>
          <Logo />
        </div>
        {isViewportEnabled && (
          <div
            role="button"
            data-test="previous-configuration"
            className={styles['container__previous-configuration']}
            onClick={() => {
              setIsViewportEnabled(false);
              trackEvent('Back button clicked');
            }}
          >
            <div className={styles['container__previous-configuration__icon']}>
              <Icon icon={PreviousArrow} />
            </div>
            <BodyText>
              <FormattedMessage id="demo-shop.configuration-panel.previous.configurations" />
            </BodyText>
          </div>
        )}
        <Heading className={styles['content__heading']}>
          <FormattedMessage id="demo-shop.configuration-panel.title" />
        </Heading>
        <Heading tag="h3" className={styles['content__description']}>
          <FormattedMessage id="demo-shop.configuration-panel.heading" />
        </Heading>
        {configState === State.INTEGRATION && !isViewportEnabled ? (
          <ConfigurationIntegration />
        ) : (
          <ConfigurationFlows />
        )}
      </div>
    </div>
  );
};

export default ConfigurationPanel;
