import { createContext } from 'react';

import { createConfigurationContextProvider } from './createConfigurationContextProvider';
import {
  ConfigurationLoader,
  ConfigurationParserDefinition,
  ConfigurationStatus,
  InferConfiguration,
} from './types';

type ConfigurationContextValue<D extends ConfigurationParserDefinition> = {
  src: string | null;
  status: ConfigurationStatus;
  config: InferConfiguration<D>;
  error: null | Error;
};

export const createConfigurationContext = <
  D extends ConfigurationParserDefinition
>(
  configurationLoader: ConfigurationLoader<D>
) => {
  const ConfigurationContext = createContext<ConfigurationContextValue<D>>({
    src: configurationLoader.src,
    status: ConfigurationStatus.Initial,
    config: configurationLoader.config,
    error: null,
  });
  ConfigurationContext.displayName = 'ConfigurationContext';

  const { ConfigurationContextProvider, useConfigurationContext } =
    createConfigurationContextProvider<D>({
      ConfigurationContext,
      configurationLoader,
    });

  return {
    ConfigurationContext,
    ConfigurationContextProvider,
    useConfigurationContext,
  };
};
