export enum ConfigurationStatus {
  Initial,
  Loading,
  Ready,
  Error,
}

export type ConfigurationPropertyValue = string | boolean | number;

export type PreconfiguredConfigurationPropertyParser<R> = ((
  value: string
) => R) & { defaultValue: R };

export type ConfigurationPropertyParser<R> = (
  defaultValue: R
) => PreconfiguredConfigurationPropertyParser<R>;

export type ConfigurationParserDefinition = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PreconfiguredConfigurationPropertyParser<any>
>;

export type InferFallbackConfiguration<
  D extends ConfigurationParserDefinition
> = {
  [K in keyof D]: D[K]['defaultValue'];
};

export type InferParsedConfiguration<D extends ConfigurationParserDefinition> =
  {
    [K in keyof D]: ReturnType<D[K]>;
  };

export type InferConfiguration<D extends ConfigurationParserDefinition> =
  | InferFallbackConfiguration<D>
  | InferParsedConfiguration<D>;

export type InferInitialConfiguration<D extends ConfigurationParserDefinition> =
  {
    [K in keyof D]: string;
  };

export type ConfigurationContextValue<D extends ConfigurationParserDefinition> =
  {
    src: string | null;
    status: ConfigurationStatus;
    config: InferConfiguration<D>;
    error: null | Error;
  };

export type ConfigurationLoaderOptions = {
  src: string;
};

export enum ConfigurationLoaderEvent {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export type ConfigurationLoaderEventPayload<
  D extends ConfigurationParserDefinition
> = {
  status: ConfigurationStatus;
  src: string;
  config: InferConfiguration<D>;
  error: null | Error;
};

export type ConfigurationLoaderEventHandler<
  D extends ConfigurationParserDefinition
> = (payload: ConfigurationLoaderEventPayload<D>) => void;

export type ConfigurationLoader<D extends ConfigurationParserDefinition> = {
  readonly src: string;
  readonly status: ConfigurationStatus;
  readonly config: InferConfiguration<D>;
  readonly error: null | Error;
  readonly fallbackConfig: InferFallbackConfiguration<D>;
  on: (
    event: ConfigurationLoaderEvent,
    payload: ConfigurationLoaderEventHandler<D>
  ) => boolean;
  off: (
    event: ConfigurationLoaderEvent,
    payload: ConfigurationLoaderEventHandler<D>
  ) => boolean;
  fetchConfig: () => Promise<void>;
  events: typeof ConfigurationLoaderEvent;
};
