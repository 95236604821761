import {
  bool,
  createConfigurationContext,
  createConfigurationLoader,
  oneof,
  str,
} from '@checkout-ui/shared-context-configuration';
import { SentryServiceEnvironment } from '@checkout-ui/shared-services';

export const configurationLoader = createConfigurationLoader(
  {
    SANDBOX_IFRAME_BASE_URL: str(
      'https://klarna-sandbox.paella-sandbox.billie.io'
    ),
    SEGMENT_WRITE_KEY: str('l3GvdjLFZPVUGXtkuUp4ELU2KAhgx93N'),
    SEGMENT_ENABLED: bool(true),
    SENTRY_DSN: str(
      'https://3d89d6843d4c1ff9008ed581ac25b14b@o60503.ingest.us.sentry.io/4508495461941248'
    ),
    SENTRY_DEBUG: bool(false),
    SENTRY_ENABLED: bool(true),
    SENTRY_ENVIRONMENT: oneof(
      SentryServiceEnvironment.Production,
      Object.values(SentryServiceEnvironment)
    ),
  },
  {
    src: '/runtime/config.json',
  }
);

export const { ConfigurationContextProvider, useConfigurationContext } =
  createConfigurationContext(configurationLoader);
